
.fMdyDu {
    max-width: 600px;
    margin: 0px auto 100px;
    text-align: center;
}

.connect_heading {
    color: var(--lightest-slate);
}
.connect_heading::before {
    position: relative;
    bottom: 4px;
    counter-increment: section 4;
    content: "0" counter(section) ".";
    margin-right: 10px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-md),3vw,var(--fz-xl));
    font-weight: 400;
}
.email_link {
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 1rem 1.75rem;
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    transition: var(--transition);
    
}

#connect {
    padding: 10rem 0;
}
.earxGk {
    display: flex;
    max-width: 600px;
    /* -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center; */
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
    height: auto;
    min-height: 70px;
    padding: 1rem;
    text-align: center;
    color: var(--slate);
}