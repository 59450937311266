
.dHdZWh:hover, .dHdZWh:focus {
    background-color: var(--light-navy);
    color: var(--green) !important;
}
.dHdZWh {
    text-transform: none !important;
    border-left: 2px solid var(--lightest-navy) !important;
    color: var(--slate)  !important;
    max-width: 200px;
}

.experience_heading {
    color: var(--lightest-slate);
    
}
.experience_heading::before {
    position: relative;
    bottom: 4px;
    counter-increment: section 2;
    content: "0" counter(section) ".";
    margin-right: 10px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(15px,3vw,25px);
    font-weight: 400;
}

.experience_heading::after {
    content: "";
    display: block;
    position: relative;
    top: -18px;
    width: 520px;
    height: 1px;
    margin-left: 23rem;
    background-color: var(--lightest-navy);
}

.MuiTabs-indicator {
    background-color: #64ffda !important;
    left: 0;
}

.QgHxr {
    width: 650px;
}
.QgHxr ul{
    list-style-type: none;
}

.QgHxr ul li::before {
    content: "▹";
    /* position: absolute; */
    margin-right: 15px;
    color: #64ffda;
}

.exp_tab {
    text-transform:'none';
    color:var(--slate);
}

.company_link {
    text-decoration: none;
    color: var(--green);
}